// extracted by mini-css-extract-plugin
export var blankChar = "PrimaryNavigation-module--blankChar--172be";
export var gallery = "PrimaryNavigation-module--gallery--4b788";
export var headerHome = "PrimaryNavigation-module--headerHome--f4ecb";
export var hovered = "PrimaryNavigation-module--hovered--cb137";
export var hovering = "PrimaryNavigation-module--hovering--76f75";
export var imageActive = "PrimaryNavigation-module--imageActive--9df10";
export var imageBottomLeft = "PrimaryNavigation-module--imageBottomLeft--436ca";
export var imageBottomRight = "PrimaryNavigation-module--imageBottomRight--86df6";
export var imageTopRight = "PrimaryNavigation-module--imageTopRight--e5c68";
export var menu = "PrimaryNavigation-module--menu--f13f0";
export var menuWrapper = "PrimaryNavigation-module--menuWrapper--98c03";
export var mobileMenu = "PrimaryNavigation-module--mobileMenu--25aa9";
export var nnSpinner = "PrimaryNavigation-module--nn-spinner--12c1c";