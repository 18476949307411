import React from 'react';
import { BLOCK_TYPES, IBlockHomeContent } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';
import * as styles from 'src/components/blocks/TextImage.module.scss';
import FigureImage from 'src/components/common/FigureImage';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import TextImage from 'src/components/blocks/TextImage';

type THomeContent = IBlockHomeContent & {}

export default function HomeContent({
  heading,
  homeIntro1: intro1,
  homeIntro2: intro2,
  featuredImage1,
  featuredImage2,
  parkImage,
  leafImage,
  mobileImage,
}: THomeContent) {
  return (
    <>
      <div className={[
        !heading ? 'nn-text-image-no-heading' : '',
        'nn-text-image py-60 py-lg-100 py-xl-120',
      ].filter(x => x).join(' ')}>
        <div className="container-fluid container-1920">
          <div className="row rg-30 justify-content-between">
            <div className="col-12 col-lg-6 col-xxxl-5 d-flex flex-column">
              <div className="mb-20 d-lg-none">
                <Fade bottom distance="40px">
                  <FigureImage {...mobileImage} />
                </Fade>
              </div>

              <Fade bottom distance="40px">
                <div className="flex-grow-1 w-100">
                  <h2 className={[styles.heading, 'ff-sohne fs-20 fs-md-24 fs-lg-28 fs-xl-32 mb-25'].join(' ')}>{heading}</h2>
                  <div className={[styles.intro, 'text-content fs-20 fs-md-24 fs-lg-28 fs-xl-32'].join(' ')}>{buildMarkupContent(intro1)}</div>
                </div>
              </Fade>
              <div className={['d-flex flex-column flex-lg-row rg-30 cg-30 mt-30 mt-lg-100 mt-xl-150'].join(' ')}>
                <Fade bottom distance="40px">
                  <FigureImage key={parkImage._key} {...parkImage} wrapperDisplay="w-50 w-md-100 d-inline-block" />
                </Fade>
                <div className={[styles.intro, 'd-lg-none text-content fs-20 fs-md-24 fs-lg-28 fs-xl-32'].join(' ')}>
                  {buildMarkupContent(intro2)}
                </div>
                <div className="text-end text-lg-start">
                    <FigureImage key={leafImage._key} {...leafImage} wrapperDisplay="w-50 w-md-100 d-inline-block" />
                </div>
              </div>
            </div>
            <div className="d-none d-lg-block nn-text-image-image col-12 col-lg-6 text-lg-end">
              <Fade bottom distance="40px">
                {featuredImage1 && <FigureImage {...featuredImage1} />}
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <TextImage
        heading=""
        intro={intro2}
        websiteUrl=""
        lightbox={false}
        images={[featuredImage2]}
        _type={BLOCK_TYPES.IMAGE_TEXT}
        _key="homeTextImage2"
        className="d-none d-lg-block"
      />
    </>
  );
}
