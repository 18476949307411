import React, { useState } from 'react';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import * as styles from './BuildingFeatures.module.scss';
import { IBlockBuildingFeatures } from 'src/content-block-types';
import IconClose from 'src/assets/IconClose';

export default function BuildingFeatures({ items }: IBlockBuildingFeatures) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);

  const showBuildingFeature = (itemIndex: number) => {
    setActiveIndex(value => itemIndex);
  };

  React.useEffect(() => {

  }, []);

  return (
    <div className="nn-building-features py-60 py-md-80 py-lg-100 py-xl-120 py-xxl-180">
      <div className="container-fluid container-1920">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-5 d-flex flex-column">
            <div className="mb-40 mb-lg-100 mb-xl-120">
              <ul className={styles.navigation} role="tablist">
                {items.map((item, itemIndex) => (
                  <li key={item._key}>
                    <button
                      className={[
                        itemIndex != activeIndex ? styles.btnInactive : '',
                        'btn fs-28 fs-md-32 fs-xl-36 p-0',
                        styles.navButton,
                      ].join(' ')}
                      id={`nn_building_feature_nav_${item._key}`}
                      type="button"
                      role="tab"
                      aria-controls={`nn_building_feature_content_${item._key} nn_building_feature_illustration_${item._key}`}
                      aria-selected={itemIndex == activeIndex ? 'true' : 'false'}
                      onClick={() => showBuildingFeature(itemIndex)}
                    ><span className="me-12 me-md-16">{itemIndex + 1}</span>{item.heading}</button>
                  </li>
                ))}
              </ul>
            </div>
            <div className={[styles.popupBox, "w-100 w-md-70 w-xxl-60", popupOpen ? styles.active : ''].join(' ')}>
              

              {items.map((item, itemIndex) => (
                <div
                  key={item._key}
                  className={[itemIndex != activeIndex ? 'd-none' : ''].filter(x => x).join(' ')}
                  role="tabpanel"
                  aria-labelledby={`nn_building_feature_nav_${item._key}`}
                  tabIndex="0"
                >
                  <FigureImage {...item.image} />
                  <div className="ff-sohne fw-300 mt-40 mt-xl-54">{buildMarkupContent(item.intro)}</div>
                </div>
              ))}
              <button type='button' className={styles.closeButton} onClick={() => setPopupOpen(false)}>
                <IconClose />
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            {items.map((item, itemIndex) => (
              <div
                key={item._key}
                className={[itemIndex != activeIndex ? 'd-none' : '', 'text-center'].filter(x => x).join(' ')}
                role="tabpanel"
                aria-labelledby={`nn_building_feature_illustration_${item._key}`}
                onClick={() => setPopupOpen(true)}
                tabIndex="0"
              >
                <FigureImage 
                  {...item.illustrationImage} loading='eager' 
                />
              </div>
            ))}
            <button onClick={() => setPopupOpen(true)} type='button' className={styles.readmore}>Read more</button>
          </div>
        </div>
      </div>
    </div>
  );
}
