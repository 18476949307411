import React from 'react';
import { useEffect, useReducer } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import FormInput from 'src/components/form/FormInput';
import { STATE_STATUSES } from 'src/constants/state-statuses';
import { createAction, TAction } from 'src/utils/reducer-action.helpers';
import Joi from 'joi';
import * as styles from './ContactForm.module.scss';

const schema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name is required',
    'any.required': 'Name is required',
  }),
  company: Joi.string().required().messages({
    'string.empty': 'Company is required',
    'any.required': 'Company is required',
  }),
  phone: Joi.string().required().messages({
    'string.empty': 'Contact number is required',
    'any.required': 'Contact number is required',
  }),
  email: Joi.string().required().email({ tlds: { allow: false } }).messages({
    'string.empty': 'Email is required',
    'any.required': 'Email is required',
    'string.email': 'Invalid email',
  }),
}).unknown(true);

const STATE_ACTION_FORM_SUBMIT_REQUEST = 'ACTION_FORM_SUBMIT_REQUEST';
const STATE_ACTION_FORM_SUBMIT_SUCCESS = 'ACTION_FORM_SUBMIT_SUCCESS';
const STATE_ACTION_FORM_SUBMIT_FAILURE = 'ACTION_FORM_SUBMIT_FAILURE';

type TFormEnterNowState = {
  status: STATE_STATUSES,
  message: string | null,
}

const initialState: TFormEnterNowState = {
  status: STATE_STATUSES.STATUS_IDLE,
  message: null,
};

type TActionList = TActionFormSubmitRequest | TActionFormSubmitFailure | TActionFormSubmitSuccess;

function stateReducer(state = initialState, action: TActionList) {
  switch (action.type) {
    case STATE_ACTION_FORM_SUBMIT_REQUEST:
      return {
        ...state,
        status: STATE_STATUSES.STATUS_LOADING,
        message: null,
        isWinner: null,
      };
    case STATE_ACTION_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        status: STATE_STATUSES.STATUS_SUCCESS,
        message: action.payload.message,
      };
    case STATE_ACTION_FORM_SUBMIT_FAILURE:
      return {
        ...state,
        status: STATE_STATUSES.STATUS_FAILURE,
        message: action.payload.message,
      };
    default:
      return state;
  }
}

type TActionFormSubmitRequest = TAction<typeof STATE_ACTION_FORM_SUBMIT_REQUEST, null>;

function actionFormSubmitRequest(): TActionFormSubmitRequest {
  return createAction(STATE_ACTION_FORM_SUBMIT_REQUEST, null);
}

type TActionFormSubmitFailurePayload = { message: string };
type TActionFormSubmitFailure = TAction<typeof STATE_ACTION_FORM_SUBMIT_FAILURE, TActionFormSubmitFailurePayload>;

function actionFormSubmitFailure(message: string): TActionFormSubmitFailure {
  return createAction(STATE_ACTION_FORM_SUBMIT_FAILURE, { message });
}

type TActionFormSubmitSuccessPayload = { message: string };
type TActionFormSubmitSuccess = TAction<typeof STATE_ACTION_FORM_SUBMIT_SUCCESS, TActionFormSubmitSuccessPayload>;

function actionFormSubmitSuccess(message: string): TActionFormSubmitSuccess {
  return createAction(STATE_ACTION_FORM_SUBMIT_SUCCESS, { message });
}

async function apiSubmitForm(data: any) {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((field) => {
      formData.append(field, data[field]);
    });

    // https://zapier.com/editor/212306176/published/212306177/sample
    const response = await fetch('https://hooks.zapier.com/hooks/catch/2181815/38risck/', {
      method: 'POST',
      body: formData,
    });

    const responseJson = await response.json();

    console.log(response)

    if (!response.ok) {
      return Promise.reject(new Error(responseJson?.message || 'Unknown error'));
    }

    return responseJson;
  } catch (error) {
    return Promise.reject(new Error(error instanceof Error ? error?.message : 'Unknown error'));
  }
}

export type TContactFormProps = {
  wrapperClassName?: string,
}

export default function ContactForm({ wrapperClassName = '' }: TContactFormProps) {
  const formMethods = useForm({
    // defaultValues: {},
    resolver: schema ? joiResolver(schema) : undefined,
  });
  const { handleSubmit } = formMethods;
  const [state, dispatch] = useReducer(stateReducer, {
    ...initialState,
  } as any);
  const { status, message } = state;

  const isLoading = status === STATE_STATUSES.STATUS_LOADING;
  const isFailure = status === STATE_STATUSES.STATUS_FAILURE;
  const isSuccess = status === STATE_STATUSES.STATUS_SUCCESS;

  const onSubmit = async (data: any) => {
    console.log('submit', data);
    dispatch(actionFormSubmitRequest());

    try {
      const { errors, message: responseMessage } = await apiSubmitForm(data);

      if (errors && Object.keys(errors).length > 0) {
        const message = Object.values(errors)[0] as string;
        dispatch(actionFormSubmitFailure(message));
      } else {
        dispatch(actionFormSubmitSuccess(responseMessage));
      }
    } catch (error: any) {
      let message = error instanceof Error ? error.message : String(error);
      dispatch(actionFormSubmitFailure(message));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSuccess]);


  const onError = (error: any) => {
    console.log('error', error);
  };

  return (
    <div className={wrapperClassName}>
      {/* form submitted success */}
      {isSuccess && (
        <div><h3>{ 'Thank you for reaching out.'}<br />{ 'We\'ll be in touch very soon!' }</h3></div>
      )}

      {/* form */}
      {!isSuccess && (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <FormProvider {...formMethods}>
            <FormInput name="name" label="Name" placeholder="Enter" />
            <FormInput name="company" label="Company" placeholder="Enter" />
            <FormInput name="email" label="Email" placeholder="Enter" />
            <FormInput name="phone" label="Contact Number" placeholder="Enter" />

            {/* submit */}
            <div className="mt-20 mt-md-40">
              <button
                type="submit"
                className={[styles.button,
                  isLoading ? 'loading' : '',
                ].filter(x => x).join(' ')}
              >Submit</button>
              {isFailure && <div className="text-danger">{message}</div>}
            </div>
          </FormProvider>
        </form>
      )}
    </div>
  );
}
