import 'src/scss/main.scss';
import React, { useEffect } from 'react';
import HeaderHome from 'src/components/HeaderHome';
import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import GlobalGallery from 'src/components/blocks/GlobalGallery';

const withDefaultLayout = ({ children, path, data }) => {
  const siteSettings = useStaticQuery(graphql`
      query SiteSettings {
        sanitySettings {
          primaryMenu {
            _key
            _type
            label
            newTab
            path
            image {
              _key
              asset {
                gatsbyImageData(
                  height: 320
                )
              }
              alt
              caption
            }
          }
          footerMenu {
            ... on SanityMenuItem {
              _key
              _type
              label
              newTab
              path
            }
            ... on SanityMenuItemPopup {
              _key
              _type
              label
              content {
                children {
                  _key
                  text
                  marks
                }
              }
            }
          }
          footerCopyright
        }
      }
    `);

  const slug = data?.sanityPage?.slug?.current;
  const isHome = slug === 'home';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [path]);

  return (
    <div className="site-page">
      <Header primaryMenu={siteSettings.sanitySettings.primaryMenu} path={path} isHome={isHome} />
      <div id="content" className="site-content">
        <main className="site-main">
          {children}
        </main>
      </div>
      {isHome ? <HeaderHome primaryMenu={siteSettings.sanitySettings.primaryMenu} path={path} /> : null}
      <Footer
        footerMenu={siteSettings.sanitySettings.footerMenu}
        copyright={siteSettings.sanitySettings.footerCopyright}
        slug={slug}
      />
      <GlobalGallery blocks={data?.sanityPage?.contentBlocks} />
    </div>
  );
};

export default withDefaultLayout;
