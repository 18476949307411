import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import * as styles from './HeroHome.module.scss';
import FigureImage from 'src/components/common/FigureImage';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IBlockHeroHome } from 'src/content-block-types';

const gallery1Settings = {
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // fade: true,
  autoplay: true,
  initialSlide: 0,
};

const gallery2Settings = {
  ...gallery1Settings,
  initialSlide: 1,
  rtl: true,
};

export default function HeroHome({ logo, heading, heroGallery1, heroGallery2 }: IBlockHeroHome) {
  const [activeGallery, setActiveGallery] = useState(false);
  const [activeContent, setActiveContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActiveContent(true);
    }, 500);

    setTimeout(() => {
      setActiveGallery(true);
    }, 1000);
  }, []);

  return (
    <div className={styles.holder}>
      <div className={styles.bgImage}>
        <Slider {...gallery1Settings} className={['nn-hero-gallery nn-hero-gallery-left', activeGallery ? 'active' : ''].filter(x => x).join(' ')}>
          {heroGallery1.map(item => (
            <div className="nn-hero-gallery-item" key={item._key}><FigureImage {...item} wrapperDisplay="d-flex h-100" loading={'eager'} /></div>
          ))}
        </Slider>
        <Slider {...gallery2Settings} className={['nn-hero-gallery nn-hero-gallery-right', activeGallery ? 'active' : ''].filter(x => x).join(' ')}>
          {heroGallery2.map(item => (
            <div className="nn-hero-gallery-item" key={item._key}><FigureImage {...item} wrapperDisplay="d-flex h-100" loading={'eager'} /></div>
          ))}
        </Slider>
      </div>
      <div className={[
        styles.content,
        'd-flex align-items-center py-60',
        activeContent ? styles.contentActive : '',
      ].filter(x => x).join(' ')}>
        <div className="container-fluid container-1920 text-center">
          <h1 className="mb-0">
            <span className="visually-hidden">{heading}</span>
            <GatsbyImage loading="eager" image={logo.asset.gatsbyImageData} alt="" />
          </h1>
        </div>
      </div>
    </div>
  );
}
