import React from 'react';
import * as styles from './Heading.module.scss';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import { IBlockHeading } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function Heading({ content }: IBlockHeading) {
  return (
    <div className={styles.holder}>
      <div className={[styles.content, 'd-flex align-items-center py-60 pt-120'].join(' ')}>
        <div className="container-fluid container-1920 text-center">
          <Fade bottom distance='40px'>
            <h2 className="fs-32 fs-md-40 fs-lg-60 fs-xl-72 fs-xxl-96 mb-0">{buildMarkupContent(content)}</h2>
          </Fade>
        </div>
      </div>
    </div>
  );
}
