import FigureImage from 'src/components/common/FigureImage';
import React from 'react';
import { IBlockBasicImage } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function BasicImage({ desktopImage, mobileImage }: IBlockBasicImage) {
  return (
    <div className="nn-image py-30 py-md-60 py-lg-90">
      <div className="container-fluid container-1920 text-center">
        <Fade bottom distance="40px">
          <FigureImage {...desktopImage} wrapperClassName='d-none d-md-block' />
          <FigureImage {...mobileImage} wrapperClassName='d-block d-md-none' />
        </Fade>
      </div>
    </div>
  );
}
