import React, { useState } from 'react';
import FigureImage from 'src/components/common/FigureImage';
import * as styles from 'src/components/blocks/Viewlines.module.scss';
import { IBlockViewlines } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

const maxSpanOffset = 200;

export default function Viewlines({ heading, gallery1: gallery }: IBlockViewlines) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [spanValue, setSpanValue] = useState(0);
  const [menuActive, setMenuActive] = useState(false);
  const spanPercent = spanValue / maxSpanOffset * 100;

  const showContent = (newActiveIndex) => {
    setActiveIndex(value => newActiveIndex);
    setMenuActive(false);
  };

  const updateSpanValue = (newValue) => {
    setSpanValue(value => newValue);
  };

  return (
    <div className={[styles.container, "nn-viewlines py-60 py-md-80 py-lg-100 py-xxl-120"].join(' ')}>
      <Fade bottom distance='40px'>
        <div className="container-fluid container-1920">
          <div className="row flex-md-nowrap gx-lg-100 gx-xl-170">
            <div className="col-12 col-md-auto">
              <h2 className="ff-sohne fs-20 fs-md-24 fs-lg-28 fs-xl-32 text-uppercase">{heading}</h2>
            </div>
            <div className="col-12 col-md-auto">
              <div className={styles.listWrapper}>
                <h3 onClick={() => setMenuActive(!menuActive)} className={[styles.listHeading, menuActive ? styles.listHeadingActive : '', 'btn ff-sohne fs-20 fs-md-24 fs-lg-28 p-0'].join(' ')}>
                  <span>{gallery[activeIndex]?.caption}</span>

                  <svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.257 386.257">
                    <polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
                  </svg>
                </h3>
                <ul className={[styles.list, menuActive ? styles.listActive : ''].join(' ')}>
                  {gallery.map((item, itemIndex) => (
                    <li key={item._key}>
                      <button
                        type="button"
                        className={[
                          itemIndex != activeIndex ? 'opacity-50' : '',
                          'btn ff-sohne fs-20 fs-md-24 fs-lg-28 p-0',
                        ].join(' ')}
                        id={`nn_viewlines_nav_${item._key}`}
                        role="tab"
                        aria-controls={`nn_viewlines_content_${item._key}`}
                        aria-selected={itemIndex == activeIndex ? 'true' : 'false'}
                        onClick={() => showContent(itemIndex)}
                      >{ item?.caption?.toUpperCase().replace('LEVEL', '') }</button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={[styles.gallery, 'mt-60'].join(' ')}>
            {gallery.map((item, itemIndex) => (
              <div
                key={item._key}
                id={`nn_viewlines_content_${item._key}`}
                className={[
                  itemIndex != activeIndex ? 'd-none' : '',
                  styles.galleryItem,
                  'd-flex align-items-stretch',
                ].filter(x => x).join(' ')}
                role="tabpanel"
                aria-labelledby={`nn_viewlines_nav_${item._key}`}
                tabIndex="0"
              >
                <FigureImage
                  {...item}
                  showCaption={false}
                  style={{
                    '--nn-viewlines-offset-left': `${spanPercent}%`,
                  } as React.CSSProperties}
                />
              </div>
            ))}
          </div>
          <div className={['mt-30'].join(' ')}>
            <input
              className={[styles.navigationSlider, 'w-100'].join(' ')}
              type="range"
              min={0}
              max={maxSpanOffset}
              value={spanValue}
              onChange={(e) => updateSpanValue(e.target.value)}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
}
