import React from 'react';
import FigureImage from 'src/components/common/FigureImage';
import { IBlockOfficeIcons } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function OfficeIcons({ image, officeIcons, _key }: IBlockOfficeIcons) {
  return (
    <div className="nn-office-icons py-60 py-md-80 py-lg-100 py-xxl-120">
      <div className="container-fluid container-1920">
        <div className="row rg-60 gx-xl-60">
          <div className="col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <FigureImage {...image} _key={_key}  lightbox={true} />
            </Fade>
          </div>
          <div className="col-12 col-lg-6 col-xxl-4">
            <div className="row rg-40 rg-lg-60">
              {officeIcons.map(item => (
                <div key={item._key} className="col-12 col-sm-6">
                  <Fade bottom distance='40px'>
                    <FigureImage {...item.icon} wrapperClassName="mb-20" />
                    <h3 className="ff-sohne fs-16 text-uppercase mb-10">{item.heading}</h3>
                    <div className="ff-sohne fw-300">{item.intro}</div>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
