import React, { useState, useEffect, useRef } from 'react';
import * as styles from './HeaderHome.module.scss';
import { createAction, TAction } from 'src/utils/reducer-action.helpers';
import { TMenuItem } from 'src/sanity-types';
import PrimaryNavigation from 'src/components/PrimaryNavigation';

const STATE_ACTION_UPDATE_MENU_VARIABLES = '';

type THeaderState = {
  menuItemRadiusBase: number,
};

const initialState: THeaderState = {
  menuItemRadiusBase: 0,
};

type TActionList = TActionUpdateMenuVariables;

function stateReducer(state = initialState, action: TActionList): THeaderState {
  switch (action.type) {
    case STATE_ACTION_UPDATE_MENU_VARIABLES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

type TActionUpdateMenuVariables = TAction<typeof STATE_ACTION_UPDATE_MENU_VARIABLES, null>;

function actionUpdateMenuVariables(payload): TActionUpdateMenuVariables {
  return createAction(STATE_ACTION_UPDATE_MENU_VARIABLES, payload);
}

export type THeaderProps = {
  primaryMenu: TMenuItem[],
  path: string,
  isHome: boolean,
}

const HeaderHome = ({ primaryMenu, path }: THeaderProps) => {

  const [menuActive, setMenuActive] = useState(false);

  const headerRef = useRef(null);

  useEffect(() => {
    // detect element is in viewport
    const onScroll = () => {
      const headerElement = headerRef.current;
      if (headerElement) {
        const rect = headerElement.getBoundingClientRect();
        setMenuActive(rect.top < window.innerHeight / 2);
      }
    }

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);





  return (
    <header className={styles.holder} ref={headerRef}>
      <div className="container-fluid">
        <nav>
          <PrimaryNavigation primaryMenu={primaryMenu} path={path} menuActive={menuActive} headerHome={true} />
        </nav>
      </div>
    </header>
  );
};

export default HeaderHome;
