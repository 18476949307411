import React, { useEffect, useState } from 'react';
import * as styles from './Footer.module.scss';
import { TFooterMenuItem, TMenuItemPopup } from 'src/sanity-types';
import { Link } from 'gatsby';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import IconClose from 'src/assets/IconClose';

type TFooterProps = {
  footerMenu: TFooterMenuItem[],
  copyright: string,
  slug: string,
}

export default function Footer({ footerMenu, copyright, slug }: TFooterProps) {
  const [activeModalId, setActiveModalId] = useState(null);

  const showModal = (e, item) => {
    setActiveModalId(value => item._key);
  };

  const hideModal = () => {
    setActiveModalId(null);
  };

  const onKeyDown = (e) => {
    // console.log(e);
    switch (e.key) {
      case 'Escape':
        hideModal();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <footer className={[styles.footer, 'ff-sohne fw-300 fs-14 py-25 pt-60'].join(' ')}>
      <div className="container-fluid d-flex flex-column flex-lg-row rg-20 cg-20 align-items-lg-center justify-content-between text-uppercase">
        <div>
          <Link to='/contact/' className={[slug === 'contact' ? 'd-none' : '', slug !== 'home' ? styles.button : styles.buttonDark, 'btn'].join(' ')}>
            Enquire Today
          </Link>
        </div>

        <ul className="d-flex flex-column flex-lg-row align-items-lg-center align-items-start justify-content-start rg-10 cg-10">
          {footerMenu.map(item => {
            switch (item._type) {
              case 'menuItemPopup':
                return (
                  <li key={item._key}>
                    <button
                      id={`nn_footer_menu_item_${item._key}`}
                      type="button"
                      className="btn fw-300 p-0"
                      onClick={(e) => showModal(e, item)}
                    >{item.label}</button>
                  </li>
                );
              case 'menuItem':
                return <li key={item._key}><Link to={item.path} target={item.newTab ? '_blank' : '_self'}>{item.label}</Link></li>;
            }
            return null;
          })}
          <li dangerouslySetInnerHTML={{__html: copyright}} />
        </ul>
      </div>
      {footerMenu.filter(item => item._type == 'menuItemPopup').map((item: TMenuItemPopup) => (
        <div
          key={item._key}
          className={[
            styles.modal,
            item._key == activeModalId ? styles.modalActive : '',
          ].filter(x => x).join(' ')}
          aria-hidden={activeModalId == item._key ? 'true' : 'false'}
          aria-labelledby={`nn_footer_menu_item_${item._key}`}
          tabIndex="-1"
        >
          <button
            type="button"
            className={[styles.btnClose, 'btn p-0'].join(' ')}
            aria-label="Close modal"
            onClick={hideModal}
          ><IconClose /></button>
          <div className="w-lg-80 w-xxl-60 w-xxxl-50 p-30 pt-60 pt-xxl-30 overflow-auto">
            <h3 className="fs-18 mb-8">{item.label}</h3>
            <div className="text-content fs-18">{buildMarkupContent(item.content)}</div>
          </div>
        </div>
      ))}
    </footer>
  );
}
