import { TImageData, TMarkupContent } from 'src/sanity-types';

export enum BLOCK_TYPES {
  HERO_HOME = 'heroHome',
  HEADING = 'blockHeading',
  IMAGE_TEXT = 'imageText',
  HERO_BANNER = 'heroBanner',
  BUILDING_FEATURES = 'buildingFeatures',
  GALLERY = 'gallery',
  IMAGE = 'imageWithMeta',
  BASIC_IMAGE = 'basicImage',
  NEIGHBOURHOOD = 'neighbourhood',
  ZEN_LOBBY = 'zenLobby',
  KEY_FEATURES = 'keyFeatures',
  KEY_FEATURES_2 = 'keyFeatures2',
  OFFICE_ICONS = 'officeIcons',
  TEXT_GALLERY = 'textGallery',
  WORKPLACE_TEST_FIT = 'workplaceTestFit',
  VIEWLINES = 'viewlines',
  CONTACT = 'contact',
  TEAM_CONTENT = 'teamContent',
  HOME_CONTENT = 'homeContent',
}

export type TBlockList = IBlock[];

export interface IBlock {
  _type: BLOCK_TYPES;
  _key: string;

  [key: string]: any,
}

export interface IBlockHeroHome extends IBlock {
  // bgImage: TImageData,
  logo: TImageData,
  heading: string,
  heroGallery1: TImageData[],
  heroGallery2: TImageData[],
}

export interface IBlockHeroBanner extends IBlock {
  bgImage: TImageData,
  heading: string,
}

export interface IBlockHeading extends IBlock {
  content: TMarkupContent[];
}

export interface IBlockImageText extends IBlock {
  heading: string,
  intro: TMarkupContent[],
  websiteUrl: string,
  images: TImageData[],
}

export interface IBlockBuildingFeature extends IBlock {
  heading: string,
  illustrationImage: TImageData,
  intro: TMarkupContent[],
  image: TImageData,
}

export interface IBlockBuildingFeatures extends IBlock {
  items: IBlockBuildingFeature[],
}

export interface IBlockGallery extends IBlock {
  alignment: string,
  images: TImageData[],
}

export interface IBlockTeamContent extends IBlock {
  teamContent: IBlockImageText[],
}

export interface IBlockHomeContent extends IBlock {
  mobileImage: TImageData,
  heading: string,
  homeIntro1: TMarkupContent[],
  homeIntro2: TMarkupContent[],
  featuredImage1: TImageData,
  featuredImage2: TImageData,
  parkImage: TImageData,
  leafImage: TImageData,
}

type TNeighbourhoodListItem = {
  _key: string,
  key: string,
  name: string,
}

type TNeighbourhoodList = {
  _key: string,
  heading: string,
  neighbourhood: TNeighbourhoodListItem[],
  gallery: IBlockGallery,
}

type TNeighbourhoodItem = {
  _key: string,
  heading: string,
  map: TImageData,
  intro: TNeighbourhoodList[],
  gallery: IBlockGallery,
}

export interface IBlockNeighbourhood extends IBlock {
  heading: string,
  items: TNeighbourhoodItem[],
}

export interface IBlockZenLobby extends IBlock {
  heading: string,
  intro: TMarkupContent[],
  lobby: TImageData,
  gallery: IBlockGallery,
}

type TKeyFeature = {
  _key: string,
  heading: string,
  image: TImageData,
}

export interface IBlockKeyFeatures extends IBlock {
  heading: string,
  intro: TMarkupContent[],
  keyFeatures: TKeyFeature[],
  gallery: IBlockGallery,
}

export interface IBlockKeyFeatures2 extends IBlock {
  heading: string,
  intro: TMarkupContent[],
  keyFeatures2: string[],
  image: TImageData,
}

type TOfficeIcon = {
  _key: string,
  icon: TImageData,
  heading: string,
  intro: string,
}

export interface IBlockOfficeIcons extends IBlock {
  image: TImageData,
  officeIcons: TOfficeIcon[],
}

export interface IBlockTextGallery extends IBlock {
  heading: string,
  intro1: string,
  gallery1: TImageData[],
}

type TMetricItemDefault = {
  _key: string,
  _type: 'workplaceTestFitMetric',
  key: string,
  heading: string,
  value: string,
}

type TMetricItemBlank = {
  _key: string,
  _type: 'workplaceTestFitMetricBlank',
  key: string,
  heading: string,
  value: string,
}

type TMetricItem = TMetricItemDefault | TMetricItemBlank;

type TWorkplaceTestFitItemOption = {
  _key: string,
  _type: string,
  heading: string,
  image: TImageData,
  metrics: TMetricItem[],
}

type TWorkplaceTestFitItem = {
  _key: string,
  _type: string,
  heading: string,
  options: TWorkplaceTestFitItemOption[],
}

export interface IBlockWorkplaceTestFit extends IBlock {
  heading: string,
  items: TWorkplaceTestFitItem[];
}

export interface IBlockViewlines extends IBlock {
  heading: string,
  gallery1: TImageData[];
}

export interface IBlockContact extends IBlock {
  heading: string,
  intro: TMarkupContent[],
  image: TImageData;
}

export interface IBlockBasicImage extends IBlock {
  desktopImage: TImageData,
  mobileImage: TImageData,
}
