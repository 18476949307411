import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

export type TFormInputProps = {
  viewMode?: boolean;
  name: string;
  label?: string | React.ReactElement;
  floatingLabel?: boolean;
  wrapperClass?: string;
  emptyValue?: string;
  [key: string]: any;
}

export default function FormInput({
  viewMode = false,
  name,
  label,
  floatingLabel = false,
  wrapperClass = '',
  emptyValue = '',
  placeholder = '',
  ...inputAttrs
}: TFormInputProps) {
  const { control } = useFormContext();
  const { field, fieldState: { invalid, isDirty, error } } = useController({ control, name, defaultValue: emptyValue || '' });
  const { value } = field;

  // const isValid = !invalid && isDirty && value;
  // console.log({ control, name, invalid, isDirty, error });
  const inputId = `form_${name}`;

  const fieldValue = emptyValue ? (value || emptyValue) : value;

  return (
    <div className={[
      'form-field-input',
      wrapperClass,
      floatingLabel ? 'form-floating' : '',
    ].filter(x => x).join(' ')}>
      <div className={[
        'form-field-input-field',
        invalid ? 'is-invalid' : '',
      ].filter(x => x).join(' ')}>
        {!floatingLabel && label && <label htmlFor={inputId} className="form-label">{label}</label>}
        <input
          id={inputId}
          className={[
            'form-control',
            invalid ? 'is-invalid' : '',
            // isValid ? 'is-valid' : '',
            fieldValue ? 'has-content' : '',
          ].filter(x => x).join(' ')}
          {...field}
          value={fieldValue || ''}
          {...inputAttrs}
          readOnly={viewMode}
          placeholder={floatingLabel ? (label as any) : placeholder}
        />
        {floatingLabel && label && <label htmlFor={inputId} className="form-label">{label}</label>}
      </div>
      {invalid && <div className="invalid-feedback">{error?.message}</div>}
    </div>
  );
}
