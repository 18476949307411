import React from 'react';
import * as styles from './TextImage.module.scss';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import { IBlockImageText } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

type TBlockTextImage = IBlockImageText & {
  className?: string,
}

export default function TextImage({ heading, intro, websiteUrl, images, className = '', lightbox = true }: TBlockTextImage) {
  const [featuredImage, ...otherImages] = images
  const introContent = buildMarkupContent(intro);
  return (
    <div className={[
      !heading ? 'nn-text-image-no-heading' : '',
      'nn-text-image py-30 py-md-60 py-lg-90',
      className,
    ].filter(x => x).join(' ')}>
      <div className="container-fluid container-1920">
        <div className="row rg-30 justify-content-between">
          <div className="col-12 col-lg-6 col-xxxl-5 d-flex flex-column">
            <Fade bottom distance="40px">
              <div className="flex-grow-1 w-100">
                {heading && <h2 className={[styles.heading, 'ff-sohne fs-20 fs-md-24 fs-lg-28 fs-xl-32 mb-25'].join(' ')}>{heading}</h2>}
                {introContent && <div className={[styles.intro, 'text-content fs-20 fs-md-24 fs-lg-28 fs-xl-32'].join(' ')}>{introContent}</div>}
                {websiteUrl && (
                  <div className="mt-40">
                    <a
                      href={websiteUrl.startsWith('//') || websiteUrl.startsWith('http') ? websiteUrl : `//${websiteUrl}`}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className={[styles.link, 'ff-sohne fw-300 text-decoration-none'].join(' ')}
                    >{websiteUrl}</a>
                  </div>
                )}
              </div>
            </Fade>
            {Array.isArray(otherImages) && otherImages.length > 0
              ? (
                <Fade bottom distance="40px">
                  <div className={['d-flex rg-30 cg-30 mt-30 mt-lg-100 mt-xl-150'].join(' ')}>
                    {otherImages.map(item => <FigureImage key={item._key} {...item} wrapperDisplay="d-flex flex-column" lightbox={lightbox} />)}
                  </div>
                </Fade>
              ) : null}
          </div>
          <Fade bottom distance="40px">
            <div className="nn-text-image-image col-12 col-lg-6 text-lg-end">
              {featuredImage && <FigureImage {...featuredImage} lightbox={lightbox} />}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
