import React from "react"
import FigureImage from "src/components/common/FigureImage"
import { IBlockTextGallery } from "src/content-block-types"
import Fade from "react-reveal/Fade"
import ImageSlider from "src/components/common/ImageSlider"
import * as styles from "./TextGallery.module.scss"

export default function TextGallery({ heading, intro1: intro, gallery1: gallery }: IBlockTextGallery) {
  const [featuredImage, ...sliderImages] = gallery

  return (
    <div className="nn-text-gallery py-60 py-md-80 py-lg-100 py-xxl-120">
      <div className="container-fluid container-1920">
        <div className="row rg-25 rg-md-30 rg-lg-60 rg-xl-90">
          <div className="col-12 col-lg-6">
            <Fade bottom distance="40px">
              <h2 className="ff-sohne mb-0" dangerouslySetInnerHTML={{ __html: heading.split("\n").join("<br/>") }} />
            </Fade>
          </div>
          <Fade bottom distance="40px">
            <div className={[styles.intro, "col-12 col-lg-6 text-content fs-20 fs-md-24 fs-lg-28 fs-xl-32"].join(' ')}>{intro}</div>
          </Fade>
          <div className="col-12 col-lg-6">
            <Fade bottom distance="40px">
              <FigureImage {...featuredImage} imageWrapperClass={'w-md-60'} />
            </Fade>
          </div>
          <div className="col-12 col-lg-6">
            {
              sliderImages.length < 2
                ? (
                  <Fade bottom distance="40px">
                    <FigureImage 
                      imageWrapperClass="d-flex align-items-stretch h-100 w-100 w-md-50"
                      {...sliderImages[0]} 
                    />
                  </Fade>
                )
                : <ImageSlider images={sliderImages} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}
