import React, { useState } from "react"
import { IBlockTeamContent } from "src/content-block-types"
import * as styles from "./TeamContent.module.scss"
import { buildMarkupContent } from "src/components/ContentBuilder"
import Fade from "react-reveal/Fade"
import FigureImage from "src/components/common/FigureImage"

type TTeamContentProps = IBlockTeamContent & {}

export default function TeamContent({ teamContent: content }: TTeamContentProps) {
  const [activeIndex, setActiveIndex] = useState(0)

  function showContent(itemIndex: number) {
    setActiveIndex(itemIndex)
  }

  return (
    <div className="nn-team-content py-60">
      <div className="container-fluid container-1920">
        <div className="row">
          <div className="col-12 col-lg-6">
            <ul className={[styles.navigation, "text-uppercase d-flex flex-column rg-5 mb-30 mb-lg-0"].join(" ")} role="tablist">
              {content.map((item, itemIndex) => (
                <li key={item._key}>
                  <h2
                    className={[
                      // activeRootIndex != itemIndex ? styles.btnInactive : '',
                      "d-flex ff-sohne lh-1 mb-0"
                    ].filter(x => x).join(" ")}
                  >
                    <button
                      className={[
                        activeIndex != itemIndex ? styles.btnInactive : "",
                        "btn ff-sohne fs-20 fs-md-24 lh-1 p-0"
                      ].join(" ")}
                      type="button"
                      onClick={() => showContent(itemIndex)}
                      id={`nn_team_content_nav_${item._key}`}
                      aria-controls={`nn_team_content_content_${item._key} nn_team_content_gallery_${item._key}`}
                      aria-selected={itemIndex == activeIndex ? "true" : "false"}
                    >
                      {item.heading}
                    </button>
                  </h2>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-lg-6">
            {content.map((item, itemIndex) => (
              <div
                id={`nn_team_content_content_${item._key}`}
                key={item._key}
                className={[itemIndex != activeIndex ? "d-none" : "", "fw-300"].filter(x => x).join(" ")}
                role="tabpanel"
                aria-labelledby={`nn_team_content_nav_${item._key}`}
                tabIndex={0}
              >
                <div className="text-content fs-20 fs-md-24 fs-lg-28 fs-xl-32" style={{lineHeight: '1.2'}}>
                  {buildMarkupContent(item.intro)}
                </div>
                {item.websiteUrl && (
                  <div className="mt-25">
                    <a
                      href={item.websiteUrl.startsWith("//") || item.websiteUrl.startsWith("http") ? item.websiteUrl : `//${item.websiteUrl}`}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className={[styles.link, "ff-sohne fw-300 text-decoration-none"].join(" ")}
                    >{item.websiteUrl}</a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {content.map((item, itemIndex) => (
          <div
            key={item._key}
            id={`nn_team_content_content_${item._key}`}
            className={[itemIndex != activeIndex ? "d-none" : "", "row rg-30 rg-md-60 rg-lg-90 ff-sohne fw-300 pt-30 pt-md-40 align-items-end"].filter(x => x).join(" ")}
            role="tabpanel"
            aria-labelledby={`nn_team_content_gallery_${item._key}`}
            tabIndex={0}
          >
            <div className="col-12 col-lg-6">
              <Fade bottom distance="40px">
                <FigureImage 
                  key={item.images[0]._key} {...item.images[0]} lightbox={true} 
                  wrapperDisplay='flex'
                  wrapperClassName={[item.images[0]?.alignment == 'right' ? `row justify-content-end text-end` : ''].join(' ')}
                  imageWrapperClass={[`w-md-${item.images[0]?.maxWidth}`].join(' ')}
                />
              </Fade>
            </div>
            {
              item.images.length > 1
                ? (
                  <div className={[
                    "col-12 col-lg-6 d-none d-md-block",
                    itemIndex % 2 == 1 ? "d-flex justify-content-lg-end" : ""
                  ].join(" ")}>
                    <Fade bottom distance="40px">
                      <FigureImage 
                        key={item.images[1]._key} {...item.images[1]} lightbox={true} 
                        wrapperDisplay='flex'
                        wrapperClassName={[item.images[1]?.alignment == 'right' ? `row justify-content-end text-end` : ''].join(' ')}
                        imageWrapperClass={[`w-md-${item.images[1]?.maxWidth}`].join(' ')}
                      />
                    </Fade>
                  </div>
                )
                : null
            }
          </div>
        ))}
      </div>
    </div>
  )
}
