import React, { useEffect, useRef, useState } from 'react';
import * as styles from 'src/components/blocks/Gallery.module.scss';
import IconClose from 'src/assets/IconClose';
import Slider from 'react-slick';
import FigureImage from 'src/components/common/FigureImage';
import PubSub from 'pubsub-js';
import { BLOCK_TYPES, TBlockList } from 'src/content-block-types';
import { set } from 'react-hook-form';

var sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  // fade: true,
  // autoplay: true,
  accessibility: true,
};

const PUBSUB_ACTION_SHOW_LIGHTBOX = 'nn_pubsub_global_gallery_show_lightbox';

export const showLightbox = (e, key) => {
  if (window.innerWidth > 900)
    PubSub.publish(PUBSUB_ACTION_SHOW_LIGHTBOX, { key });
};

type TGlobalGalleryProps = {
  blocks: TBlockList | any,
};

const blocksToImages = (blocks) => {
  if (!Array.isArray(blocks)) return null
  return blocks.reduce((images, block) => {
    //console.log(block._type)
    switch (block._type) {
      case BLOCK_TYPES.GALLERY:
        return images.concat([...block.images]);
      case BLOCK_TYPES.IMAGE_TEXT:
        return images.concat([...block.images]);
      case BLOCK_TYPES.ZEN_LOBBY:
        return images.concat([...block.gallery.images]);
      case BLOCK_TYPES.KEY_FEATURES:
        return images.concat(block.gallery.images);
      case BLOCK_TYPES.KEY_FEATURES_2:
        return images.concat([{ ...block.image, _key: block._key }]);
      case BLOCK_TYPES.NEIGHBOURHOOD:
        return images.concat([...block.gallery.images]);
      case BLOCK_TYPES.OFFICE_ICONS:
        return images.concat([{ ...block.image, _key: block._key }]);
      case BLOCK_TYPES.TEXT_GALLERY:
        return images.concat([...block.gallery1]);
      case BLOCK_TYPES.IMAGE:
        return images.concat([{ ...block, _key: block._key }]);
      // case BLOCK_TYPES.BUILDING_FEATURES:
      //   return images.concat(block.items.map(item => item.image));
      case BLOCK_TYPES.TEAM_CONTENT:
        return images.concat(block.teamContent.map(item => item.images).flat());
      default:
        //console.log('GlobalGallery: unknown block type', block._type)
        return images;
    }
  }, []).filter(el => el.asset);
}

export default function GlobalGallery({ blocks }: TGlobalGalleryProps) {
  const sliderRef = useRef();
  const lastKey = useRef(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [currentSliderIndex, setCurrentSliderIndex] = useState(1);
  const [images, setImages] = useState(blocksToImages(blocks));

  // const showLightbox = (e, itemIndex) => {
  //   setActiveIndex(value => itemIndex);
  // };

  useEffect(() => {
    let nextImages = blocksToImages(blocks)
    setImages(nextImages);

    const onShowLightbox = (message, { key }) => {
      lastKey.current = key;

      let nextImages = blocksToImages(blocks)
      let nextIndex = nextImages?.findIndex(item => item._key == key);
      //if (process.env.NODE_ENV === 'development') console.log('onShowLightbox', key, nextIndex, images);
      if (nextIndex < 0) nextIndex = 0;
      setActiveIndex(nextIndex);
      sliderRef.current?.slickGoTo(nextIndex);
    };

    const showLightboxToken = PubSub.subscribe(PUBSUB_ACTION_SHOW_LIGHTBOX, onShowLightbox);

    return () => {
      PubSub.unsubscribe(showLightboxToken);
    };

  }, [blocks]);

  useEffect(() => {
    //if (activeIndex < 0) return;
    let key = lastKey.current;
    let nextIndex = images?.findIndex(item => item._key == key);
    if (nextIndex < 0) return;
    setActiveIndex(nextIndex);
    sliderRef.current?.slickGoTo(nextIndex);
  }, [images]);

  const hideLightbox = () => {
    setActiveIndex(value => -1);
  };

  const onKeyDown = (e) => {
    // console.log(e);
    switch (e.key) {
      case 'Escape':
        hideLightbox();
        break;
      case 'ArrowLeft':
        setTimeout(() => {
          sliderRef.current.slickGoTo(currentSliderIndex - 1);
        }, 0);
        break;
      case 'ArrowRight':
        setTimeout(() => {
          sliderRef.current.slickGoTo(currentSliderIndex + 1);
        }, 0);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);

    };
  }, [onKeyDown, currentSliderIndex]);

  const afterSliderChange = (newIndex) => {
    setCurrentSliderIndex(newIndex);
  };

  useEffect(() => {
    setCurrentSliderIndex(activeIndex);
  }, [activeIndex]);

  if (!Array.isArray(images) || images.length < 1) {
    return null;
  }

  return (
    <div className={[styles.lightbox, 'd-flex align-items-stretch py-30 px-30', activeIndex >= 0 ? styles.lightboxActive : ''].join(' ')}>
      <button
        type="button"
        className={[styles.lightboxClose, 'btn p-0'].join(' ')}
        onClick={hideLightbox}
      ><IconClose /><span className="visually-hidden">Close lightbox</span></button>
      <div className="position-relative w-100 px-10 px-md-40">
        {images?.length > 0 && (
          <Slider
            {...sliderSettings}
            afterChange={afterSliderChange}
            //initialSlide={activeIndex}
            className="nn-gallery-lightbox"
            ref={sliderRef}
          >
            {images.map((item, idx) => (
              <div
                key={item._key || idx}
                className="nn-gallery-lightbox-item d-flex align-items-center align-items-lg-stretch w-100"
              >
                <FigureImage {...item} wrapperClassName="d-flex flex-column align-items-stretch position-relative w-100" />
              </div>
            ))}
          </Slider>
        )}
        <div className={[styles.lightboxSliderPagination, 'd-none d-md-block ff-sohne fw-300'].join(' ')}>{currentSliderIndex + 1}/{images.length}</div>
      </div>
    </div>
  );
}
