import React from 'react';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import { IBlockZenLobby } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';
import * as styles from './ZenLobby.module.scss';

export default function ZenLobby({ _key, heading, intro, lobby, gallery }: IBlockZenLobby) {
  return (
    <div className="nn-zen-lobby py-40 py-md-60 py-lg-90 py-xl-90">
      <div className="container-fluid container-1920">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <h2 className="mb-30 fs-20 fs-md-24 fs-lg-28 ff-sohne" dangerouslySetInnerHTML={{ __html: heading.split('\n').join('<br/>') }} />
              <div className="text-content ff-sohne fs-19 fw-300 w-lg-80 w-xxl-50 mb-30 mb-md-60 mb-lg-90 mb-xxl-90">{buildMarkupContent(intro)}</div>
              <div className={styles.mainImage}>
                <FigureImage {...lobby} />
              </div>
            </Fade>
          </div>
          <div className="d-none d-md-block col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <div className="d-flex flex-wrap rg-30 rg-lg-60 rg-xl-90">
                {gallery.images.map((item, itemIndex) => (
                  <FigureImage
                    {...item}
                    wrapperClassName={itemIndex > 0 ? 'col-6' : 'col-12'}
                    lightbox={true}
                  />
                ))}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
