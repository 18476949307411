import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './HeroBanner.module.scss';
import { IBlockHeroBanner } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function HeroBanner({ bgImage, heading }: IBlockHeroBanner) {
  return (
    <div className={[styles.holder, 'nn-hero-banner'].join(' ')}>
      <div className={styles.bgImage}>
        <GatsbyImage
          className={styles.image}
          image={bgImage.asset.gatsbyImageData}
          loading='eager'
          alt={bgImage.alt || ''}
        />
      </div>
      <div className={[styles.content, 'd-flex align-items-center py-80'].join(' ')}>
        <div className="container-fluid container-1920 text-center text-uppercase ff-sohne">
          <Fade bottom distance='40px'>
            <h1 className="fs-32 fs-md-50 fs-lg-72 fs-xxl-80">{heading}</h1>
          </Fade>
        </div>
      </div>
    </div>
  );
}
