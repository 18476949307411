import React from 'react';
import FigureImage from 'src/components/common/FigureImage';
import { IBlockGallery } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

type TGalleryProps = IBlockGallery & {
  container: boolean,
}

export default function Gallery({ alignment, images, container = true }: TGalleryProps) {
  return (
    <div className="nn-gallery py-30">
      <div className={[
        container ? 'container-fluid container-1920' : '',
      ].filter(x => x).join(' ')}>
        <div className={[
          'row',
          alignment == 'right' ? 'justify-content-end' : '',
        ].filter(x => x).join(' ')}>
          <Fade bottom distance="40px">
            <div className="col-12 col-lg-8 d-flex flex-wrap flex-md-nowrap cg-30 rg-30 cg-md-60 cg-lg-90">
              {images.map(item => (
                <div key={item._key}>
                  <FigureImage 
                    {...item} lightbox={true} 
                  />
                </div>
              ))}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
