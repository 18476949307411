import React from 'react';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import * as styles from './KeyFeatures.module.scss';
import { IBlockKeyFeatures } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';
import ImageSlider from "src/components/common/ImageSlider"

export default function KeyFeatures({ heading, intro, keyFeatures, gallery }: IBlockKeyFeatures) {
  return (
    <div className="nn-key-features py-60 py-md-80 py-lg-100 py-xl-120 py-xxl-180">
      <div className="container-fluid container-1920">
        <div className="row rg-40 rg-md-30">
          <div className="col-12 col-lg-6 d-flex flex-column">
            <Fade bottom distance='40px'>
              <h2 className="ff-sohne fs-20 fs-md-24 fs-lg-28 mb-30" dangerouslySetInnerHTML={{ __html: heading.split('\n').join('<br/>') }} />
              <div className="ff-sohne fw-300 fs-19 text-content w-lg-80 w-xxl-60 mb-0 mb-lg-60 flex-grow-1">
                {buildMarkupContent(intro)}
              </div>
              <div className="row rg-30">
                {keyFeatures.map(item => (
                  <div key={item._key} className="col-6 col-md-4">
                    <FigureImage {...item.image} wrapperClassName={[styles.keyFeatureIcon, 'mb-10 w-100'].join(' ')} />
                    <h3 className="text-uppercase fs-16 fs-lg-20 ff-sohne mb-0" dangerouslySetInnerHTML={{ __html: item.heading.split('\n').join('<br class="d-none d-sm-block d-md-none d-xxl-block"/>') }} />
                  </div>
                ))}
              </div>
            </Fade>
          </div>
          <div className="col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <ImageSlider images={gallery.images} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
