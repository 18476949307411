import React from 'react';

export default function Logo({ className }) {
  return (
    <svg className={className} width="619" height="69" viewBox="0 0 619 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.6078 46.3175C44.6078 58.7503 34.5545 69 22.3053 69C10.056 69 0 58.7503 0 46.3175C0 41.7609 1.64621 35.7824 5.1174 29.9942L22.1185 1.62151H34.3677L20.1975 24.777C21.4808 24.5839 22.7642 24.3936 24.0395 24.3936C35.1921 24.3936 44.6078 33.602 44.6078 46.3175ZM34.2797 46.2224C34.2797 39.3902 28.7914 33.7895 22.2145 33.7895C19.0128 33.7895 15.9952 34.5481 13.7994 35.4997C11.6142 39.3902 10.4189 43.0876 10.4189 46.2224C10.4189 52.8642 15.6324 58.4649 22.3053 58.4649C29.0662 58.4649 34.2797 52.8642 34.2797 46.2224Z" fill="currentColor" />
      <path d="M111.897 34.7402C111.897 54.1927 97.9138 68.999 79.4506 68.999C60.9874 68.999 46.9079 54.1927 46.9079 34.7402C46.9079 15.38 60.9874 0.671689 79.4506 0.671689C97.9138 0.671689 111.897 15.38 111.897 34.7402ZM57.9698 34.7402C57.9698 48.0267 67.2014 58.1812 79.4506 58.1812C91.6118 58.1812 100.931 48.0267 100.931 34.7402C100.931 21.5461 91.6118 11.4895 79.4506 11.4895C67.2014 11.4895 57.9698 21.5461 57.9698 34.7402Z" fill="currentColor" />
      <path d="M180.574 34.7402C180.574 54.1927 166.591 68.999 148.127 68.999C129.664 68.999 115.585 54.1927 115.585 34.7402C115.585 15.38 129.664 0.671689 148.127 0.671689C166.591 0.671689 180.574 15.38 180.574 34.7402ZM126.647 34.7402C126.647 48.0267 135.878 58.1812 148.127 58.1812C160.289 58.1812 169.608 48.0267 169.608 34.7402C169.608 21.5461 160.289 11.4895 148.127 11.4895C135.878 11.4895 126.647 21.5461 126.647 34.7402Z" fill="currentColor" />
      <path d="M202.917 1.62027V68.0471H244.232V57.4224H213.61V1.62027H202.917Z" fill="currentColor" />
      <path d="M307.874 34.7402C307.874 54.1927 293.891 68.999 275.427 68.999C256.964 68.999 242.885 54.1927 242.885 34.7402C242.885 15.38 256.964 0.671689 275.427 0.671689C293.891 0.671689 307.874 15.38 307.874 34.7402ZM253.947 34.7402C253.947 48.0267 263.178 58.1812 275.427 58.1812C287.589 58.1812 296.908 48.0267 296.908 34.7402C296.908 21.5461 287.589 11.4895 275.427 11.4895C263.178 11.4895 253.947 21.5461 253.947 34.7402Z" fill="currentColor" />
      <path d="M353.335 1.62027V46.6018L323.077 1.62027H313.757V68.0471H324.448V23.0684L354.894 68.0471H364.125V1.62027H353.335Z" fill="currentColor" />
      <path d="M370.354 46.602H380.965C380.965 53.7197 386.445 58.5562 394.127 58.5562C401.347 58.5562 406.464 54.7609 406.464 49.1631C406.464 34.1692 371.176 43.7527 371.176 19.4609C371.176 8.54788 380.319 0.668915 392.843 0.668915C407.561 0.668915 415.058 10.3504 415.058 21.5461H404.092C403.905 15.5704 399.428 11.2041 392.48 11.2041C385.992 11.2041 381.691 14.4312 381.691 19.3629C381.691 33.3155 417.067 23.6369 417.067 49.1631C417.067 59.603 408.294 68.999 394.127 68.999C380.319 68.999 370.354 59.6954 370.354 46.602Z" fill="currentColor" />
      <path d="M475.956 34.548C475.956 53.7179 462.157 68.0483 443.96 68.0483H422.941V1.62149H443.96C462.157 1.62149 475.956 15.5685 475.956 34.548ZM464.99 34.548C464.99 21.547 456.396 12.249 444.697 12.249H433.632V57.4208H444.697C456.308 57.4208 464.99 47.6442 464.99 34.548Z" fill="currentColor" />
      <path d="M497.468 1.62027L471.961 68.0471H483.297L488.327 54.7606H515.381L520.32 68.0471H531.923L506.424 1.62027H497.468ZM501.851 18.7021L511.355 44.1359H492.254L501.851 18.7021Z" fill="currentColor" />
      <path d="M534.966 1.62027V68.0471H576.279V57.4224H545.657V1.62027H534.966Z" fill="currentColor" />
      <path d="M581.244 1.62027V68.0471H619V57.4224H591.935V38.5353H616.07V27.905H591.935V12.2478H619V1.62027H581.244Z" fill="currentColor" />
    </svg>
  );
}
