import React, { CSSProperties } from 'react';
import { TImageData } from 'src/sanity-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { showLightbox } from 'src/components/blocks/GlobalGallery';

type TFigureImageProps = TImageData & {
  wrapperClassName?: string,
  wrapperDisplay?: string,
  imageWrapperClass?: string,
  loading?: string,
  showCaption?: boolean,
  lightbox?: boolean,
  style?: CSSProperties | undefined,
  onMouseEnter?: (e) => void,
  onMouseLeave?: (e) => void,
  onClick?: (e) => void,
};

export default function FigureImage({
  _key,
  wrapperClassName = '',
  wrapperDisplay = 'd-inline-block',
  imageWrapperClass = 'd-flex align-items-stretch h-100 w-100',
  asset,
  alt = '',
  caption,
  loading = 'lazy', // 'eager' | 'lazy
  showCaption = true,
  style = undefined,
  lightbox = false,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  onClick = undefined,
}: TFigureImageProps) {
  if (lightbox) {
    return (
      <button
        type="button"
        className="btn p-0 lh-1 border-0 text-start w-100"
        onClick={(e) => showLightbox(e, _key)}
      >
        <figure
          className={[wrapperClassName, wrapperDisplay, 'text-start mb-0'].filter(x => x).join(' ')}
          style={style}
        >
          <div className={imageWrapperClass}>
            <GatsbyImage image={asset?.gatsbyImageData} alt={alt || ''} />
          </div>
          {caption ? <figcaption className={['ff-sohne fw-300 mt-16', !showCaption ? 'visually-hidden' : ''].filter(x => x).join(' ')}>{caption}</figcaption> : null}
        </figure>
      </button>
    );
  }

  return (
    <figure
      className={[wrapperClassName, wrapperDisplay, 'text-start mb-0'].filter(x => x).join(' ')}
      style={style}
    >
      <div className={imageWrapperClass}>
        {onClick ? (
          <div
            style={{ cursor: 'pointer' }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
          >
            <GatsbyImage
              loading={loading}
              image={asset?.gatsbyImageData} alt={alt || ''}
            />
          </div>
        ) : (
          <GatsbyImage
            loading={loading}
            image={asset?.gatsbyImageData} alt={alt || ''}
          />
        )}
      </div>
      {caption ? <figcaption className={['ff-sohne fw-300 mt-16', !showCaption ? 'visually-hidden' : ''].filter(x => x).join(' ')}>{caption}</figcaption> : null}
    </figure>
  );
}
