import { buildMarkupContent } from 'src/components/ContentBuilder';
import React, { useState } from 'react';
import FigureImage from 'src/components/common/FigureImage';
import * as styles from './Neighbourhood.module.scss';
import { IBlockNeighbourhood } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function Neighbourhood({ heading, items, gallery, mobileImage }: IBlockNeighbourhood) {
  const [activeIndex, setActiveIndex] = useState(0);

  const showBuildingFeature = (itemIndex) => {
    setActiveIndex(value => itemIndex);
  };

  return (
    <div className="nn-neighbourhood py-30 py-md-60 py-lg-90 py-xl-120">
      <div className="container-fluid container-1920">
        <div className="row justify-content-between">
          {/* left */}
          <div className="col-12 col-lg-4 d-flex flex-column">
            <Fade bottom distance="40px">
              <h2 className="ff-sohne fs-28 fs-lg-33 mb-40">{heading}</h2>

              {/* mobile image */}
              <div className="d-block d-md-none">
                <FigureImage {...mobileImage} />
              </div>


              {/* navigation */}
              <div className="d-none d-md-block flex-grow-1 mb-40">
                <ul className={[styles.navigation, 'd-flex flex-column rg-5'].join(' ')} role="tablist">
                  {items.map((item, itemIndex) => (
                    <li key={item._key}>
                      <h3 className="d-flex lh-1 mb-0">
                        <button
                          className={[itemIndex != activeIndex ? styles.btnInactive : '', 'btn ff-sohne fs-24 lh-1 p-0'].join(' ')}
                          id={`nn_neighbourhood_nav_${item._key}`}
                          type="button"
                          role="tab"
                          aria-controls={`nn_neighbourhood_content_${item._key} nn_neighbourhood_illustration_${item._key}`}
                          aria-selected={itemIndex == activeIndex ? 'true' : 'false'}
                          onClick={() => showBuildingFeature(itemIndex)}
                        >{item.heading}</button>
                      </h3>
                    </li>
                  ))}
                </ul>
              </div>

              {/* intro */}
              <div className="d-none d-md-block w-md-70 w-xxl-60 w-xxxl-40">
                {items.map((item, itemIndex) => (
                  <div
                    key={item._key}
                    className={[itemIndex != activeIndex ? 'd-none' : ''].filter(x => x).join(' ')}
                    role="tabpanel"
                    aria-labelledby={`nn_neighbourhood_nav_${item._key}`}
                    tabIndex="0"
                  >
                    <div className="d-flex flex-column rg-30">
                      {item.intro.map(sub => (
                        <div key={sub._key}>
                          <h4 className="ff-sohne fw-400 fs-16 mb-0">{sub.heading}</h4>
                          <ul className={[styles.list, 'ff-sohne fw-300'].join(' ')}>
                            {sub.neighbourhood.map(subLvl2 => (
                              <li key={subLvl2._key}>
                                <span className={[
                                  subLvl2.key === '[bike_path]' ? styles.bikePath : '',
                                ].filter(x => x).join(' ')}>{subLvl2.key != '[bike_path]' ? subLvl2.key : ''}</span>
                                <span>{subLvl2.name}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Fade>
          </div>
          {/* right */}
          <Fade bottom distance="40px">
            <div className="d-none d-md-block col-12 col-lg-8 px-0 px-md-12">
              {items.map((item, itemIndex) => (
                <div
                  key={item._key}
                  className={[itemIndex != activeIndex ? 'd-none' : ''].filter(x => x).join(' ')}
                  role="tabpanel"
                  aria-labelledby={`nn_neighbourhood_nav_${item._key}`}
                  tabIndex="0"
                >
                  <FigureImage {...item.map} />
                </div>
              ))}
            </div>
          </Fade>
        </div>
        <div className={['row justify-content-end'].filter(x => x).join(' ')}>
          <div className="col-12 col-lg-8">
            <div className="pt-30 pt-md-60 pt-lg-90">
              <div className={[
                'row cg-30 rg-30',
                gallery.alignment == 'right' ? 'justify-content-end' : '',
              ].filter(x => x).join(' ')}>
                <Fade bottom distance="40px">
                  <div className="col-12 col-lg-8 d-flex flex-wrap flex-md-nowrap cg-30 rg-30 cg-md-60 cg-lg-90">
                    {gallery.images.map(image => (
                      <div key={image._key}>
                        <FigureImage {...image} lightbox={true} />
                      </div>
                    ))}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
