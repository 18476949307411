// extracted by mini-css-extract-plugin
export var btnInactive = "WorkplaceTestFit-module--btnInactive--6e23f";
export var circleBlue = "WorkplaceTestFit-module--circleBlue--becde";
export var circleGrey = "WorkplaceTestFit-module--circleGrey--ff63d";
export var circleRed = "WorkplaceTestFit-module--circleRed--83c16";
export var hideNav = "WorkplaceTestFit-module--hideNav--0359b";
export var mainHeading = "WorkplaceTestFit-module--mainHeading--a2e4b";
export var metrics = "WorkplaceTestFit-module--metrics--7b022";
export var navHeading = "WorkplaceTestFit-module--navHeading--c4a52";
export var navHeadingActive = "WorkplaceTestFit-module--navHeadingActive--1ea80";
export var navigation = "WorkplaceTestFit-module--navigation--96565";
export var navigationWrapper = "WorkplaceTestFit-module--navigationWrapper--ad230";
export var nnSpinner = "WorkplaceTestFit-module--nn-spinner--ddf1e";
export var showNav = "WorkplaceTestFit-module--showNav--6a949";