import React from 'react';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import * as styles from './KeyFeatures2.module.scss';
import { IBlockKeyFeatures2 } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function KeyFeatures2({ _key, heading, intro, image, keyFeatures2 }: IBlockKeyFeatures2) {
  return (
    <div className="nn-key-features py-60 py-md-80 py-lg-100 py-xl-120 py-xxl-180">
      <div className="container-fluid container-1920">
        <div className="row rg-40 rg-md-30">
          <div className="col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <h2 className="ff-sohne fs-20 fs-md-24 fs-lg-28 mb-30" dangerouslySetInnerHTML={{ __html: heading.split('\n').join('<br/>') }} />
              <div className="ff-sohne fw-300 fs-19 text-content mb-0 w-lg-60 w-xxl-60">{buildMarkupContent(intro)}</div>
              <ul className={[styles.keyFeatures, 'ff-sohne fs-18'].join(' ')}>
                {keyFeatures2.map(item => <li key={item}>{item}</li>)}
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-lg-6">
            <Fade bottom distance='40px'>
              <FigureImage {...image} _key={_key} lightbox={true} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
