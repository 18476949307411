import React, { useEffect, useRef, useState } from 'react';
import Logo from 'src/assets/Logo';
import { Link } from 'gatsby';
import PrimaryNavigation from 'src/components/PrimaryNavigation';
import { THeaderProps } from 'src/components/HeaderHome';
import * as styles from './Header.module.scss';
import IconClose from 'src/assets/IconClose';
import { set } from 'react-hook-form';

export default function Header({ primaryMenu, path, isHome }: THeaderProps) {
  const [stickyActive, setStickyActive] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [menuActive, setMenuActive] = useState(false);

  const showMenu = () => {
    document.body.classList.add(styles.menuActive);
    setMenuActive(true);
  };
  const hideMenu = () => {
    document.body.classList.remove(styles.menuActive);
    setMenuActive(false);
  };

  const onKeyDown = (e) => {
    // console.log(e);
    switch (e.key) {
      case 'Escape':
        hideMenu();
        break;
    }
  };

  useEffect(() => {
    let lastDirection = 0;

    window.hideMenu = hideMenu;

    const onScroll = () => {
      let nextHeaderVisible = headerVisible;
      if (window.scrollY > 100 && window.scrollY > lastDirection && (window.innerHeight + Math.round(window.scrollY)) < document.body.offsetHeight) {
        // console.log('down');
        nextHeaderVisible = false;
      } else {
        // console.log('up');
        nextHeaderVisible = true;
      }
      if (menuActive) nextHeaderVisible = true;
      setHeaderVisible(nextHeaderVisible);
      lastDirection = window.scrollY;
      setStickyActive(value => window.scrollY > 0);
    };
    onScroll();

    document.addEventListener('keydown', onKeyDown);
    window.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (stickyActive && !isHome) {
      document.body.classList.add('nn-header-sticky');
    } else {
      document.body.classList.remove('nn-header-sticky');
    }
  }, [stickyActive, isHome]);

  useEffect(() => {
    if (headerVisible) {
      document.body.classList.remove('nn-header-visible');
    } else if (!menuActive) {
      document.body.classList.add('nn-header-visible');
    }
  }, [headerVisible, menuActive]);

  return (
    <>
      <header className={[styles.holder, 'nn-header'].join(' ')}>
        <div className={[styles.holderInner, 'nn-header-inner py-40 py-md-30'].join(' ')}>
          <div className="container-fluid">
            <nav className="position-relative d-flex align-items-center justify-content-end justify-content-lg-center">
              <button
                type="button"
                className={[styles.menuToggler].join(' ')}
                onClick={showMenu}
              ><span className="visually-hidden">Open menu</span></button>
              <Link to="/" className={[styles.logo, isHome ? styles.logoHide : ''].join(' ')}><Logo /></Link>
            </nav>
          </div>
        </div>

      </header>
      <div className={styles.menuWrapper}>
        <button
          type="button"
          className={[styles.btnClose, 'btn'].join(' ')}
          onClick={hideMenu}
        ><IconClose /><span className="visually-hidden">Close menu</span></button>
        <PrimaryNavigation primaryMenu={primaryMenu} path={path} menuActive={menuActive} headerHome={false} />
      </div>
    </>
  );
}
