import React from 'react';
import HeroHome from 'src/components/blocks/HeroHome';
import Heading from 'src/components/blocks/Heading';
import TextImage from 'src/components/blocks/TextImage';
import HeroBanner from 'src/components/blocks/HeroBanner';
import BuildingFeatures from 'src/components/blocks/BuildingFeatures';
import Gallery from 'src/components/blocks/Gallery';
import BlockImage from 'src/components/blocks/BlockImage';
import Neighbourhood from 'src/components/blocks/Neighbourhood';
import ZenLobby from 'src/components/blocks/ZenLobby';
import KeyFeatures from 'src/components/blocks/KeyFeatures';
import KeyFeatures2 from 'src/components/blocks/KeyFeatures2';
import OfficeIcons from 'src/components/blocks/OfficeIcons';
import TextGallery from 'src/components/blocks/TextGallery';
import WorkplaceTestFit from 'src/components/blocks/WorkplaceTestFit';
import Viewlines from 'src/components/blocks/Viewlines';
import { TMarkupContent } from 'src/sanity-types';
import Contact from 'src/components/blocks/Contact';
import { BLOCK_TYPES, TBlockList } from 'src/content-block-types';
import TeamContent from 'src/components/blocks/TeamContent';
import HomeContent from 'src/components/blocks/HomeContent';
import BasicImage from './blocks/BasicImage';

type TContentBuilderProps = {
  blocks: TBlockList,
}

export default function ContentBuilder({ blocks }: TContentBuilderProps) {
  //console.log(blocks);
  return (
    <>
      {blocks.map(block => {
        switch (block._type) {
          case BLOCK_TYPES.HERO_HOME:
            return <HeroHome key={block._key} {...block as any} />;
          case BLOCK_TYPES.HEADING:
            return <Heading key={block._key} {...block as any} />;
          case BLOCK_TYPES.IMAGE_TEXT:
            return <TextImage key={block._key} {...block as any} />;
          case BLOCK_TYPES.HERO_BANNER:
            return <HeroBanner key={block._key} {...block as any} />;
          case BLOCK_TYPES.BUILDING_FEATURES:
            return <BuildingFeatures key={block._key} {...block as any} />;
          case BLOCK_TYPES.GALLERY:
            return <Gallery key={block._key} {...block as any} />;
          case BLOCK_TYPES.IMAGE:
            return <BlockImage key={block._key} {...block as any} />;
          case BLOCK_TYPES.BASIC_IMAGE:
            return <BasicImage key={block._key} {...block as any} />;
          case BLOCK_TYPES.NEIGHBOURHOOD:
            return <Neighbourhood key={block._key} {...block as any} />;
          case BLOCK_TYPES.ZEN_LOBBY:
            return <ZenLobby key={block._key} {...block as any} />;
          case BLOCK_TYPES.KEY_FEATURES:
            return <KeyFeatures key={block._key} {...block as any} />;
          case BLOCK_TYPES.KEY_FEATURES_2:
            return <KeyFeatures2 key={block._key} {...block as any} />;
          case BLOCK_TYPES.OFFICE_ICONS:
            return <OfficeIcons key={block._key} {...block as any} />;
          case BLOCK_TYPES.TEXT_GALLERY:
            return <TextGallery key={block._key} {...block as any} />;
          case BLOCK_TYPES.WORKPLACE_TEST_FIT:
            return <WorkplaceTestFit key={block._key} {...block as any} />;
          case BLOCK_TYPES.VIEWLINES:
            return <Viewlines key={block._key} {...block as any} />;
          case BLOCK_TYPES.CONTACT:
            return <Contact key={block._key} {...block as any} />;
          case BLOCK_TYPES.TEAM_CONTENT:
            return <TeamContent key={block._key} {...block as any} />;
          case BLOCK_TYPES.HOME_CONTENT:
            return <HomeContent key={block._key} {...block as any} />;
          default:
            console.log('block not found', block);
            return null;
        }
      })}
    </>
  );
}

export function buildMarkupContent(content: TMarkupContent[]) {
  if (!Array.isArray(content) || content.length < 1) {
    return null;
  }
  return (
    <>
      {content.map((item, itemIndex) => (
        <p key={itemIndex}>
          {item.children.map(sub => {
            if (sub.marks[0]) {
              return React.createElement(sub.marks[0], { key: sub._key }, sub.text);
            }
            return <span key={sub._key}>{sub.text}</span>;
          })}
        </p>
      ))}
    </>
  );
}
