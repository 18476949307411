import FigureImage from 'src/components/common/FigureImage';
import React from 'react';
import { TImageData } from 'src/sanity-types';
import Fade from 'react-reveal/Fade';

export default function BlockImage(props: TImageData) {
  return (
    <div className="nn-image py-30 py-md-60 py-lg-90">
      <div className="container-fluid container-1920 text-center">
        <Fade bottom distance="40px">
          <FigureImage {...props} />
        </Fade>
      </div>
    </div>
  );
}
