import React from 'react';
import { buildMarkupContent } from 'src/components/ContentBuilder';
import FigureImage from 'src/components/common/FigureImage';
import ContactForm from 'src/components/ContactForm';
import { IBlockContact } from 'src/content-block-types';
import Fade from 'react-reveal/Fade';

export default function Contact({ heading, intro, image }: IBlockContact) {
  const introContent = buildMarkupContent(intro);
  return (
    <div className="nn-text-image py-60 py-lg-90">
      <div className="container-fluid container-1920">
        <div className="row rg-60 rg-md-30 justify-content-between">
          <div className="col-12 col-lg-6 col-xxxl-5 d-flex flex-column">
            <Fade bottom distance="40px">
              <div className="flex-grow-1 w-100">
                {heading && <h2 className="ff-sohne fs-32 mb-25">{heading}</h2>}
                {introContent && <div className="text-content fs-20 fs-md-r17">{introContent}</div>}
              </div>
              <div className="w-xl-80 w-xxl-70 mt-30">
                <ContactForm />
              </div>
            </Fade>
          </div>
          <div className="col-12 col-lg-5 text-lg-end">
            <Fade bottom distance="40px">
              <FigureImage {...image} />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
