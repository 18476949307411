import React from 'react';

export default function IconClose() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30.424" height="30.424" viewBox="0 0 30.424 30.424">
      <g transform="translate(0 30.424)">
        <g>
          <g transform="translate(0.211 -30.212)">
            <path d="M0,0,30,30" fill="none" stroke="currentColor" strokeWidth="0.6" />
          </g>
          <g transform="translate(30.211 -30.212)">
            <path d="M0,0-30,30" fill="none" stroke="currentColor" strokeWidth="0.6" />
          </g>
        </g>
      </g>
    </svg>
  );
}
