import { TImageData } from "src/sanity-types"
import FigureImage from "src/components/common/FigureImage"
import React, { useRef } from "react"
import Fade from "react-reveal/Fade"
import Slider from "react-slick"

type TImageSliderProps = {
  images: TImageData[],
}

const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // fade: true,
  autoplay: true,
  initialSlide: 0
}

export default function ImageSlider({ images }: TImageSliderProps) {

  return (
    <Fade bottom={true} distance="40px">
      <Slider {...sliderSettings} className="nn-image-slider">
        {images.map(item => (
          <div key={item._key}>
            <FigureImage {...item} lightbox={true} />
          </div>
        ))}
      </Slider>
    </Fade>
  )
}
