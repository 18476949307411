import React, { useState } from "react"
import FigureImage from "src/components/common/FigureImage"
import * as styles from "src/components/blocks/WorkplaceTestFit.module.scss"
import { isShortcode } from "src/utils/helpers"
import { IBlockWorkplaceTestFit } from "src/content-block-types"
import Fade from "react-reveal/Fade"

export default function WorkplaceTestFit({ heading, items }: IBlockWorkplaceTestFit) {
  let initial = new Array(items.length).fill(-1)
  initial[0] = 0;
  const [activeIndex, setActiveIndex] = useState(initial)
  const [menuActive, setMenuActive] = useState(new Array(items.length).fill(false))

  const showSub = (rootIndex: number, subIndex: number) => {
    setActiveIndex(value => {
      const newValue = new Array(items.length).fill(-1) 
      newValue[rootIndex] = subIndex
      console.log(newValue)
      return newValue
    })
  }

  const toggleMenu = (rootIndex: number) => {
    setMenuActive(value => {
      const newValue = new Array(items.length).fill(false) 
      newValue[rootIndex] = !value[rootIndex]
      return newValue
    })
  }

  return (
    <div className="nn-workplace-test-fit py-60 py-md-80 py-lg-100 py-xxl-120">
      <div className="container-fluid container-1920">
        <Fade bottom distance="40px">
          <h2 className={[styles.mainHeading, "ff-sohne fs-20 fs-md-24 fs-lg-28 fs-xl-32 text-uppercase mb-60 mb-lg-90"].join(' ')}>{heading}</h2>
        </Fade>
        <div className="row justify-content-between">
          {/* left */}
          <Fade bottom distance="40px">
            <div className="col-12 col-lg-6 col-xl-5 d-flex flex-column mb-0 justify-content-between">
              {/* navigation */}
              <div className={[styles.navigationWrapper, "mb-40"].join(' ')}>
                {items.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <h3 key={itemIndex} className={[styles.navHeading, menuActive[itemIndex] ? styles.navHeadingActive : '', 'ff-sohne fs-18 fs-md-20 fs-lg-24 fs-xl-28 text-uppercase'].join(' ')} onClick={() => toggleMenu(itemIndex)}>
                      <span>
                        {item?.heading}
                      </span>
                      <svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.257 386.257">
                        <polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
                      </svg>
                    </h3>
                    <ul 
                      style={{height: menuActive[itemIndex] ? item.options.length * 28 : 0}}
                      className={[styles.navigation, menuActive[itemIndex] ? styles.showNav : styles.hideNav].join(" ")} 
                      role="tablist"
                    >
                      {item.options.map((sub, subIndex) => (
                        <li key={sub._key}>
                          <h4 className="d-flex lh-1 mb-5">
                            <button
                              className={[
                                activeIndex[itemIndex] !== subIndex ? styles.btnInactive : "",
                                "btn ff-sohne fs-18 fs-md-20 fs-lg-24 fs-xl-28 text-uppercase lh-1 p-0"
                              ].join(" ")}
                              id={`nn_workplace_test_fit_nav_sub_${sub._key}`}
                              type="button"
                              role="tab"
                              aria-controls={`nn_workplace_test_fit_content_${sub._key} nn_workplace_test_fit_image_${sub._key}`}
                              aria-selected={subIndex === activeIndex[itemIndex] ? "true" : "false"}
                              onClick={() => showSub(itemIndex, subIndex)}
                            >
                              {sub.heading}
                            </button>
                          </h4>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}


              </div>

              {/* metrics */}
              <div className="d-none d-md-block w-md-70 w-xxl-60 w-xxxl-50">
                {items.map((item, itemIndex) =>
                  item.options.map((sub, subIndex) => (
                    <div
                      id={`nn_workplace_test_fit_content_${sub._key}`}
                      key={sub._key}
                      className={[subIndex !== activeIndex[itemIndex] ? "d-none" : "", "ff-sohne fw-300"].filter(x => x).join(" ")}
                      role="tabpanel"
                      aria-labelledby={`nn_workplace_test_fit_nav_sub_${sub._key}`}
                      tabIndex={0}
                    >
                      <table className={[styles.metrics, "table table-borderless lh-1"].join(" ")}>
                        <thead className="fs-14 fs-sm-16">
                          <tr>
                            <th colSpan={2} className="fw-400 pb-20">Metrics</th>
                            <th className="fw-400 pb-20">Total Shown</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sub.metrics.map(metric => {
                            switch (metric._type) {
                              case "workplaceTestFitMetricBlank":
                                return (
                                  <tr key={metric._key}>
                                    <td colSpan={3}>&nbsp;</td>
                                  </tr>
                                )
                              default:
                                return (
                                  <tr key={metric._key}>
                                    <td className={[
                                      metric.key == "[color_grey]" ? styles.circleGrey : "",
                                      metric.key == "[color_red]" ? styles.circleRed : "",
                                      metric.key == "[color_blue]" ? styles.circleBlue : ""
                                    ].filter(x => x).join(" ")}>{!isShortcode(metric.key) ? metric.key : ""}</td>
                                    <td>{metric.heading}</td>
                                    <td>{metric.value}</td>
                                  </tr>
                                )
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  ))
                )}
              </div>
            </div>
          </Fade>
          {/* right */}
          <div className="col-12 col-lg-6 text-center">
            {items.map((item, itemIndex) =>
              item.options.map((sub, subIndex) => (
                <div
                  id={`nn_workplace_test_fit_image_${sub._key}`}
                  key={sub._key}
                  className={[subIndex !== activeIndex[itemIndex] ? "d-none" : ""].filter(x => x).join(" ")}
                  role="tabpanel"
                  aria-labelledby={`nn_workplace_test_fit_nav_sub_${sub._key}`}
                  tabIndex={0}
                >
                  <Fade bottom distance="40px">
                    <FigureImage
                      key={sub._key}
                      {...sub.image}
                      wrapperClassName={[subIndex !== activeIndex[itemIndex] ? "d-none" : ""].filter(x => x).join(" ")}
                    />
                  </Fade>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
