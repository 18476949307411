import { Link, navigate } from 'gatsby';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TMenuItem } from 'src/sanity-types';
import { useWindowSize } from 'usehooks-ts';
import * as styles from './PrimaryNavigation.module.scss';
import FigureImage from 'src/components/common/FigureImage';

function getRotateBaseDeg(char: string) {
  switch (char) {
    case 'A':
      return 4.1;
    case 'E':
      return 3.8;
    case 'L':
      return 3.5;
    case 'T':
      return 3.5;
    case 'F':
      return 3.5;
    case 'I':
      return 2.4;
    case 'M':
      return 4.7;
    case 'H':
      return 4.3;
    case '6':
      return 3.7;
    case '0':
      return 3.5;
  }
  return 4;
}

function calculateImageTransform(itemIndex, itemCount) {
  if (itemCount == 7) {
    switch (itemIndex) {
      case 1:
        return { x: 57, y: 16 };
      case 2:
        return { x: 66, y: 46 };
      case 3:
        return { x: 50, y: 66 };
      case 4:
        return { x: 26, y: 60 };
      case 5:
        return { x: 12, y: 30 };
      case 6:
        return { x: 35, y: 10 };
    }
  }
  return { x: 0, y: 0 };
}

function prepareMenuItemsWidthRotateDeg(rawMenuItemList: TMenuItem[]) {
  const menuItemList = rawMenuItemList.map((item) => ({
    ...item,
    label: item.label.toUpperCase(),
  }));
  const itemCount = menuItemList.length;
  const firstItemTotalDeg = menuItemList[0].label.split('').reduce((total, char) => total + getRotateBaseDeg(char), 0);
  const totalCharDeg = menuItemList.reduce((count, item) => count + item.label.split('').reduce((subTotal, char) => subTotal + getRotateBaseDeg(char), 0), 0);
  const itemGap = (360 - totalCharDeg) / menuItemList.length;
  let rotateDegOffset = -itemGap - firstItemTotalDeg / 2;
  return menuItemList.map((item, itemIndex) => {
    rotateDegOffset += itemGap;
    const chars = item.label.split('').map((char, charIndex) => {
      let charBaseDeg = getRotateBaseDeg(char);
      rotateDegOffset += charBaseDeg / 2;
      const result = {
        char,
        rotateDegOffset,
      };
      rotateDegOffset += charBaseDeg / 2;
      return result;
    });
    // const { x: imageTransformX, y: imageTransformY } = calculateImageTransform(itemIndex, itemCount);
    return {
      ...item,
      chars,
      // imageTransform: `translate(${imageTransformX}%, ${imageTransformY}%)`,
    };
  });
}

function getItemDeg(itemList, itemIndex) {
  try {
    const item = itemList[itemIndex];
    return -(item.chars[0].rotateDegOffset + item.chars[item.chars.length - 1].rotateDegOffset) / 2;
  } catch (error) {
    return 0;
  }
}

function calculateRotateDeg(itemList, rotateDeg, prevIndex, itemIndex): number {
  
  if (itemIndex === prevIndex) {
    return rotateDeg;
  }

  const prevRotateDeg = getItemDeg(itemList, prevIndex);
  const nextRotateDeg = getItemDeg(itemList, itemIndex);
  let diff = Math.abs(nextRotateDeg - prevRotateDeg);
  let isClockwise = itemIndex > prevIndex || (prevIndex == itemList.length - 1 && itemIndex == 0);
  // console.log(1, { isClockwise, diff, prevIndex, itemIndex });

  if (diff > 180) {
    isClockwise = !isClockwise;
    diff = 360 - diff;
  }
  // console.log(2, { isClockwise, diff, prevIndex, itemIndex });

  if (isClockwise) {
    return rotateDeg - diff;
  }

  if (prevIndex == itemList.length - 1 && itemIndex == 0) {
    return rotateDeg - diff;
  }

  return rotateDeg + diff;
}

type TPrimaryNavigationProps = {
  primaryMenu: TMenuItem[],
  path: string,
  headerHome: boolean,
  menuActive: boolean,
}

export default function PrimaryNavigation({ primaryMenu, path, headerHome, menuActive }: TPrimaryNavigationProps) {

  const currentPathTrailingSlash = path.replace(/\/$/, '');
  const { width } = useWindowSize();

  const menuItemsWithRotateDeg = prepareMenuItemsWidthRotateDeg(primaryMenu);

  const menuPrevActiveIndex = useRef(0);
  const menuPrevRotateDeg = useRef(0);

  const [scale, setScale] = useState(1);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [spinSpeed, setSpinSpeed] = useState('3s');
  const [spinEase, setSpinEase] = useState('linear');

  const navigationTimeoutRef = useRef<number | null>(null);
  const rotateTimeoutRef = useRef<number | null>(null);
  const contRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    menuPrevActiveIndex.current = primaryMenu.findIndex(item => item.path == currentPathTrailingSlash);
    setRotateDeg(calculateRotateDeg(menuItemsWithRotateDeg, 0, 0, menuPrevActiveIndex.current));
  }, []);

  useEffect(() => {
    setScale(value => Math.min(1, (width - 60) / 580));
  }, [width]);

  useEffect(() => {

    if (typeof rotateTimeoutRef.current == 'number') {
      clearTimeout(rotateTimeoutRef.current) 
      rotateTimeoutRef.current = null
    }

    if (menuActive && hoverIndex === -1 && activeIndex === -1) {
      menuPrevRotateDeg.current = rotateDeg
      setSpinSpeed('50ms')
      setSpinEase('linear')
      // Slowly rotate wheel
      
      const repeatRotate = () => {
        rotateTimeoutRef.current = window.setTimeout(() => {
          requestAnimationFrame(() => {
            setRotateDeg(value => {
              repeatRotate()
              return value + 0.2
            })
          })
        }, 1000 / 60)
      }
      repeatRotate()
      
    } else {
      menuPrevRotateDeg.current = 0
      setSpinSpeed('1s')
      setSpinEase('ease-in-out')
      // Set Rotate Deg to current value
      if (typeof rotateTimeoutRef.current == 'number') {
        clearTimeout(rotateTimeoutRef.current)
        rotateTimeoutRef.current = null
      }
    }

  }, [menuActive, hoverIndex, activeIndex]);

  useEffect(() => {
    if (activeIndex < 0) {
      return;
    }

    if (typeof navigationTimeoutRef.current == 'number') {
      clearTimeout(navigationTimeoutRef.current);
      navigationTimeoutRef.current = null;
    }

    setRotateDeg(value => {
      navigationTimeoutRef.current = null;
      let newRotateDeg = menuPrevRotateDeg.current;

      let timeDelay = 400

      if (activeIndex === 0) {
        // Home doesn't have an image so animation should be faster
        timeDelay = 400
      }

      newRotateDeg = calculateRotateDeg(menuItemsWithRotateDeg, value, menuPrevActiveIndex.current, activeIndex);

      navigationTimeoutRef.current = window.setTimeout(async () => {
        const item = menuItemsWithRotateDeg[activeIndex];
        await navigate(item.path);

        menuPrevActiveIndex.current = activeIndex;

        setTimeout(() => {
          if (typeof window?.hideMenu === 'function') window.hideMenu()
          setHoverIndex(-1);
          setActiveIndex(-1);
          setRotateDeg(0);
        }, 1800)
      }, timeDelay);

      return newRotateDeg;
    });
  }, [activeIndex]);

  const handleClickLink = (e, itemIndex) => {
    e.preventDefault();

    if (typeof navigationTimeoutRef.current == 'number') {
      clearTimeout(navigationTimeoutRef.current);
      navigationTimeoutRef.current = null;
    }

    if (headerHome && contRef?.current) {
      contRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    setActiveIndex(itemIndex);
  };

  const handleMouseEnterLink = (e, itemIndex) => {
    setHoverIndex(prev => itemIndex);
    if (typeof rotateTimeoutRef.current == 'number') {
      clearTimeout(rotateTimeoutRef.current)
      rotateTimeoutRef.current = null
    }
  };

  const handleMouseLeaveLink = (e, itemIndex) => {
    setHoverIndex(prev => prev === itemIndex ? -1 : prev);
    if (typeof rotateTimeoutRef.current == 'number') {
      clearTimeout(rotateTimeoutRef.current)
      rotateTimeoutRef.current = null
    }
  };

  return (
    <div ref={contRef} className={[styles.menuWrapper, 'nn-primary-menu w-100 ff-sohne'].join(' ')}>
      { !headerHome && (
        <div className={styles.mobileMenu}>
          <ul>
            {primaryMenu.map((item, itemIndex) => {
              return (
                <li key={item._key}>
                  <Link
                    to={item.path}
                    target={item.newTab ? '_blank' : '_self'}
                  >
                    <span>{item.label}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      <div
        className={[styles.menu, 'm-auto', headerHome ? styles.headerHome : ''].join(' ')}
        style={{
          '--nn-primary-menu-scale': scale,
          '--nn-primary-menu-rotate': rotateDeg + 'deg',
        } as React.CSSProperties}
      >
        <ul style={{transitionDuration: spinSpeed, transitionTimingFunction: spinEase}}>
          {menuItemsWithRotateDeg.map((item, itemIndex) => {
            return (
              <li key={item._key}>
                <Link
                  to={item.path}
                  target={item.newTab ? '_blank' : '_self'}
                  style={hoverIndex !== -1 && hoverIndex !== itemIndex ? {opacity: 0.5} : {}}
                  onClick={(e) => handleClickLink(e, itemIndex)}
                  onMouseEnter={(e) => handleMouseEnterLink(e, itemIndex)}
                  onMouseLeave={(e) => handleMouseLeaveLink(e, itemIndex)}
                >
                  <span className="visually-hidden">{item.label}</span>
                  {item.chars.map((charItem, charIndex) => {
                    return (
                      <span
                        key={charIndex}
                        aria-hidden="true"
                        style={{
                          '--nn-primary-menu-item-rotate-deg': `${charItem.rotateDegOffset}deg`,
                          // '--nn-primary-menu-item-transform-offset': styles.blankChar ,
                        } as React.CSSProperties}
                        className={[charItem.char == ' ' ? styles.blankChar : ''].filter(x => x).join(' ')}
                      >{ charItem.char }</span>
                    );
                  })}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={[styles.gallery].join(' ')}>
        <ul>
          {menuItemsWithRotateDeg.map((item, itemIndex) =>
            item.image
              ? (
                <li
                  key={item._key}
                // style={{
                //   '--nn-primary-menu-image-transform': item.imageTransform,
                // } as React.CSSProperties}
                >
                  <FigureImage
                    {...item.image}
                    onMouseEnter={(e) => {
                      handleMouseEnterLink(e, itemIndex)
                      if (activeIndex !== -1 || !menuActive) return;
                      //setActiveIndex(itemIndex);
                      
                      setRotateDeg(value => {
                        menuPrevActiveIndex.current = itemIndex;
                        let nextDeg = getItemDeg(menuItemsWithRotateDeg, itemIndex);
                        //console.log(value, nextDeg)
                        let diff = Math.abs(nextDeg - value);
                        //console.log(diff)
                        if (diff > 180) {
                          nextDeg = 360 - diff + value
                          //nextDeg = value - diff;
                        }
                        return nextDeg
                      });
                    }}
                    onMouseLeave={(e) => {
                      handleMouseLeaveLink(e, itemIndex)
                      if (activeIndex !== -1 || !menuActive) return;

                      //menuPrevActiveIndex.current = primaryMenu.findIndex(item => item.path == currentPathTrailingSlash);
                      //setRotateDeg(calculateRotateDeg(menuItemsWithRotateDeg, 0, 0, menuPrevActiveIndex.current));
                    }}
                    onClick={(e) => handleClickLink(e, itemIndex)}
                    wrapperClassName={[
                      hoverIndex !== -1 && itemIndex !== hoverIndex ? styles.hovering : '',
                      hoverIndex === itemIndex ? styles.hovered : '',
                      itemIndex === activeIndex && styles.imageActive,
                      itemIndex <= menuItemsWithRotateDeg.length / 4 ? styles.imageTopRight : '',
                      menuItemsWithRotateDeg.length / 4 < itemIndex && itemIndex <= menuItemsWithRotateDeg.length / 2 ? styles.imageBottomRight : '',
                      menuItemsWithRotateDeg.length / 2 < itemIndex && itemIndex <= menuItemsWithRotateDeg.length * 3 / 4 ? styles.imageBottomLeft : '',
                    ].filter(x => x).join(' ')}
                    imageWrapperClass=""
                  />
                </li>
              )
              : null,
          )}
        </ul>
      </div>
    </div>
  );
}
